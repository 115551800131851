var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.selected)?_c('UserProfile',{attrs:{"propped-user":_vm.user,"tabs":_vm.dynamicTabs,"profile-fields":[
                'date_of_birth',     'id',
      'home_address',
       'emergency_contact',
'number_of_dependants',
'tax_number',
      'medical_information',
      'non_learner_medical'
    ],"canCreateEnrolment":!!_vm.$store.getters['entities/user-permissions/find']('create enrolments'),"canDeleteEnrolment":!!_vm.$store.getters['entities/user-permissions/find']('delete enrolments'),"canEditEnrolment":!!_vm.$store.getters['entities/user-permissions/find']('edit enrolments'),"canCreateSubject":!!_vm.$store.getters['entities/user-permissions/find']('create subjects'),"canDeleteSubject":!!_vm.$store.getters['entities/user-permissions/find']('delete subjects'),"canEditSubject":!!_vm.$store.getters['entities/user-permissions/find']('edit subjects'),"canCreateExtramural":!!_vm.$store.getters['entities/user-permissions/find']('create extramurals'),"canDeleteExtramural":!!_vm.$store.getters['entities/user-permissions/find']('delete extramurals'),"canEditExtramural":!!_vm.$store.getters['entities/user-permissions/find']('edit extramurals'),"canCreate":!!_vm.$store.getters['entities/user-permissions/find']('create users'),"canDelete":!!_vm.$store.getters['entities/user-permissions/find']('delete users'),"canEdit":!!_vm.$store.getters['entities/user-permissions/find']('edit users'),"canCreateGuardian":!!_vm.$store.getters['entities/user-permissions/find']('edit learners'),"canDeleteGuardian":!!_vm.$store.getters['entities/user-permissions/find']('edit learners'),"canEditGuardian":!!_vm.$store.getters['entities/user-permissions/find']('edit learners'),"canCreateWard":!!_vm.$store.getters['entities/user-permissions/find']('edit guardians'),"canDeleteWard":!!_vm.$store.getters['entities/user-permissions/find']('edit guardians'),"canEditWard":!!_vm.$store.getters['entities/user-permissions/find']('edit guardians'),"can-create-learner":!!_vm.$store.getters['entities/user-permissions/find']('create learners')},on:{"deleted":_vm.handleDelete}}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }