<template>
  <UserProfile
      @deleted="handleDelete"
      :propped-user="user"
      :tabs="dynamicTabs"
      :profile-fields="[
                'date_of_birth',     'id',
      'home_address',
       'emergency_contact',
'number_of_dependants',
'tax_number',
      'medical_information',
      'non_learner_medical'
    ]"
      v-if="selected"
      :canCreateEnrolment="
      !!$store.getters['entities/user-permissions/find']('create enrolments')
    "
      :canDeleteEnrolment="
      !!$store.getters['entities/user-permissions/find']('delete enrolments')
    "
      :canEditEnrolment="
      !!$store.getters['entities/user-permissions/find']('edit enrolments')
    "
      :canCreateSubject="
      !!$store.getters['entities/user-permissions/find']('create subjects')
    "
      :canDeleteSubject="
      !!$store.getters['entities/user-permissions/find']('delete subjects')
    "
      :canEditSubject="
      !!$store.getters['entities/user-permissions/find']('edit subjects')
    "
      :canCreateExtramural="
      !!$store.getters['entities/user-permissions/find']('create extramurals')
    "
      :canDeleteExtramural="
      !!$store.getters['entities/user-permissions/find']('delete extramurals')
    "
      :canEditExtramural="
      !!$store.getters['entities/user-permissions/find']('edit extramurals')
    "
      :canCreate="
      !!$store.getters['entities/user-permissions/find']('create users')
    "
      :canDelete="
      !!$store.getters['entities/user-permissions/find']('delete users')
    "
      :canEdit="!!$store.getters['entities/user-permissions/find']('edit users')"
      :canCreateGuardian="
      !!$store.getters['entities/user-permissions/find']('edit learners')
    "
      :canDeleteGuardian="
      !!$store.getters['entities/user-permissions/find']('edit learners')
    "
      :canEditGuardian="
      !!$store.getters['entities/user-permissions/find']('edit learners')
    "
      :canCreateWard="
      !!$store.getters['entities/user-permissions/find']('edit guardians')
    "
      :canDeleteWard="
      !!$store.getters['entities/user-permissions/find']('edit guardians')
    "
      :canEditWard="
      !!$store.getters['entities/user-permissions/find']('edit guardians')
    "
      :can-create-learner="
      !!$store.getters['entities/user-permissions/find']('create learners')
    "
  ></UserProfile>
</template>

<script>
import UserProfile from "@/components/users/UserProfile";
import User from "@/models/User";

export default {
  props: ["id"],
  components: {
    UserProfile,
  },
  data() {
    return {
      selected: false,
      user: null,
    };
  },
  computed:{
    dynamicTabs() {

      return      [{ title: 'User Details', role: 'details' }, { title: 'Profile', role: 'profile' },
        ...(this.$store.getters['entities/user-permissions/find']('create staff-profiles') ? [{title:'Employee Info',role:'staff-profile'}] : []),
        ...(this.$store.getters['entities/user-permissions/find']('view leave-requests') ? [{title:'Leave Requests',role: 'leave-requests'}] : []),
        {title:'Incidents',role:'incidents'},
        {title:'Covid Assessments',role: 'covid-assessments'},
      ]


    }
  },
  methods: {
    handleDelete() {
      this.$router.push("/staff");
    },
  },
  mounted() {
    User.FetchById(this.id).then(({entities}) => {
      this.user = entities.users[0];
      this.selected = true;
    });
  },
};
</script>
